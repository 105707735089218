import React, { useState } from "react";
import { AppAlertsContext } from "../../../context/app-alerts-context";
import { InlineAlert, ConfirmationModal } from "../../components";
import "./app-alerts.scss";
import {ALERTS_DISAPPEAR_AFTER_MS} from "../../../config/config-options";

export const AlertType = {
  ERROR: 'danger',
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
}

const AppAlerts = ({children}) => {
  const [dismissibleAlert, setDismissibleAlert] = useState(null);
  const [autoDismissedAlert, setAutoDismissedAlert] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(null);

  const popError = (message) => {
    setAutoDismissedAlert({
      id: Date.now().toString(),
      message,
      variant: AlertType.ERROR,
    });
  }

  const popAlert = (message, alertType) => {
    setAutoDismissedAlert({
      id: Date.now().toString(),
      message,
      variant: alertType,
    });
  }

  const popDismissibleAlert = (message, alertType, additionalOptions = {}) => {
    setDismissibleAlert({
      id: Date.now().toString(),
      message,
      variant: alertType,
      additionalOptions,
    });
  }

  const popConfirm = (markdown, onConfirm, additionalOptions = {}) => {
    setConfirmPopup({
      markdown: markdown,
      onConfirm: async () => {
        await onConfirm();
        setConfirmPopup(null);
      },
      show: true,
      ...additionalOptions,
    });
  }

  const clearConfirm = () => {
    setConfirmPopup(null);
  }

  return <AppAlertsContext.Provider value={{
    popError,
    popAlert,
    popDismissibleAlert,
    popConfirm,
    clearConfirm,
  }}>
    <div className="app-alert-container">
      <InlineAlert
        className="app-alert"
        alert={dismissibleAlert}
        dismissible
      />
      <InlineAlert
        className="app-alert"
        alert={autoDismissedAlert}
        variant={autoDismissedAlert?.variant}
        disappearAfter={ALERTS_DISAPPEAR_AFTER_MS}
      />
      <ConfirmationModal
        messageMarkdown={confirmPopup?.markdown}
        onConfirm={confirmPopup?.onConfirm}
        onHide={() => {setConfirmPopup(null); confirmPopup?.onHide?.();}}
        show={confirmPopup?.show}
        disableForSeconds={confirmPopup?.disableForSeconds}
        confirmButtonText={confirmPopup?.confirmButtonText}
        cancelButtonText={confirmPopup?.cancelButtonText}
      />
    </div>
    {children}
  </AppAlertsContext.Provider>
}

export default AppAlerts;
