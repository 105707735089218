import React, {useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import classNames from "classnames";
import "./markdown.scss";
import {Helmet} from "react-helmet";
import {InjectHiddenText} from "./InjectHiddenText";
import rehypeRaw from 'rehype-raw';

const Markdown = React.memo(({children, className, includeCopyAICheat, ...rest}) => {
  const [scripts, setScripts] = useState([]);
  const paragraphCount = useRef(0);
  const currentIndex = useRef(0);

  useEffect(() => {
    if (!children || !children.match) {
      return setScripts([]);
    }

    // Count paragraphs in the markdown content
    const paragraphs = children.toString().split('\n\n').filter(p => p.trim());
    paragraphCount.current = paragraphs.length;
    currentIndex.current = 0;

    let scripts = children.match(/<script[\s\S]*?>[\s\S]*?<\/script>/gi) || [];
    setScripts(scripts.map(s => s
      .replace(/<script[\s\S]*?>/gi, "")
      .replace(/<\/script>/gi, ""),
    ));
  }, [children]);

  const components = {
    p: ({node, children, ...props}) => {
      const index = currentIndex.current++;
      const total = paragraphCount.current;
      const middleIndex = Math.floor((total - 1) / 3);
      const isMiddle = index === middleIndex;
      
      if (includeCopyAICheat) {
        return (
          <p {...props} data-p-index={index} data-p-total={total}>
            {isMiddle && (
              <InjectHiddenText>
                {children}
              </InjectHiddenText>
            )}
            {!isMiddle && children}
          </p>
        );
      }
      return (
        <p {...props} data-p-index={index} data-p-total={total}>
          {children}
        </p>
      );
    },
    span: ({node, children, ...props}) => {
      if (includeCopyAICheat) {
        return (
          <p {...props}>
            <InjectHiddenText wordsInterval={4}>{children}</InjectHiddenText>
          </p>
        );
      }
      return <p {...props}>{children}</p>;
    },
  };

  // Reset the current index before each render
  currentIndex.current = 0;

  return <>
    <ReactMarkdown
      className={classNames(["markdown", className])}
      linkTarget="_blank"
      rehypePlugins={[rehypeRaw]}
      components={components}
    >
      {children}
    </ReactMarkdown>
    <Helmet>
      {scripts.map((s, i) => {
        return <script key={i}>{s}</script>;
      })}
    </Helmet>
  </>;
});

Markdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  includeCopyAICheat: PropTypes.bool,
};

export default Markdown;
