module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/kevinoflaherty/Documents/side-projects/great-gotham-challenge/src/components/layouts/app-layout/AppLayout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Great Gotham Challenge","short_name":"GGC","start_url":"/cm/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"static/icons/logox192.png","icons":[{"src":"/icons/logox192.png","sizes":"192x192","type":"image/png"},{"src":"/icons/logox512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4fb72454ee344b8282ee318d84e2fbfb"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
