import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const HintSummarySegement = ({isAvailable, isWalkthrough, index, viewingHint, canNotGoBack}) => {
  let displayText = isWalkthrough ? "Walkthrough" : index + 1;
  if (!isAvailable) {
    displayText = "Taken";
  }
  return <div className={classNames("hint-summary-segment", {
    "hint-summary-segment--unavailable": !isAvailable || canNotGoBack,
    "hint-summary-segment--viewing": viewingHint,
  })}>
    {displayText}
  </div>
}

HintSummarySegement.propTypes = {
  isAvailable: PropTypes.bool,
  isWalkthrough: PropTypes.bool,
  index: PropTypes.number,
  viewingHint: PropTypes.bool,
  canNotGoBack: PropTypes.bool,
};

export default HintSummarySegement;
