import React, {useContext, useState} from "react";
import {Button} from "react-bootstrap";
import {HintQuestionModal} from "../../../../components/components";
import useHintQuestionsFlow, {HINT_DISABLED_REASONS} from "../../../../hooks/useHintQuestionsFlow";
import appContent from "../../../../markdown/app-content";
import useInterval from "../../../../hooks/useInterval";
import {splitMinutes} from "../../../../utilities/dates";
import {InfoSquare} from "react-bootstrap-icons";
import "../hint-button/hint-button.scss";
import GameContext from "../../../../context/game-context";
import HintSummarySegement from "./HintSummarySegment";
import CountdownButton from "../../../../components/countdown-button/CountdownButton";
import {AppAlertsContext} from "../../../../context/app-alerts-context";

const hintNavigationButtonClass = "hint-nav-button";

const HintButton = () => {
  const {
    askTakeHint,
    currentHint,
    showingHintQuestion,
    hasHints,
    moreHintsRemain,
    answerHint,
    canGoBack,
    goToPreviousHint,
    disableNoAnswer,
    disableYesAnswer,
    getHintPenalty,
    getWalkthroughPenalty,
    getHintWillUnlockTimeInMin,
    disableHintButton,
    disabledHintButtonReason,
    playerNameTakingHint,
    progressText,
    onLastHintBeforeAWalkthrough,
    liveHintButton,
    walkthroughAvailable,
    onlyWalkthroughLeft,
    hintSummary,
  } = useHintQuestionsFlow();

  const {game} = useContext(GameContext);
  const [realtimeHintPenalty, setRealtimeHintPenalty] = useState(splitMinutes(getHintPenalty()));
  const [realtimeWalkthroughPenalty, setRealtimeWalkthroughPenalty] = useState(splitMinutes(getWalkthroughPenalty()));
  const [realtimeHintLockedTime, setRealtimeHintLockedTime] = useState(splitMinutes(getHintWillUnlockTimeInMin()));
  const {popConfirm} = useContext(AppAlertsContext);
  const [showingConfirmationModal, setShowingConfirmationModal] = useState(false);

  const confirmTakeHint = (callback) => {
    setShowingConfirmationModal(true);
    const confirm = (message) => popConfirm(message, () => {
      setShowingConfirmationModal(false)
      setTimeout(callback(), 500);
    }, {
      onHide: () => setShowingConfirmationModal(false),
      confirmButtonText: "Confirm",
      cancelButtonText: "Back",
    });

    if (currentHint.isWalkthrough) {
      confirm(`You are about to take a walkthrough.\n\nIs this what you'd like to do?`);
    } else {
      confirm(`You are about to take a hint that answers the following question:\n\n<b>${currentHint.question}</b>\n\nIs this the hint you'd like to take?`);
    }
  }

  const getHintButtonText = (options) => {
    const {isLiveHintButton, liveHintsEnabled} = options;
    const disabledReason = isLiveHintButton ? liveHintButton.disabledReason : disabledHintButtonReason;

    if (disabledReason === HINT_DISABLED_REASONS.NOT_STARTED) {
      return appContent.inGame.hints.hintButtonTextWhenNotStarted({liveHintsEnabled: liveHintsEnabled});
    } else if (disabledReason === HINT_DISABLED_REASONS.PLAYER_TAKING_HINT) {
      return appContent.inGame.hints.hintButtonTextWhileDisabledWhilePlayerInHintFlow(playerNameTakingHint);
    } else if (disabledReason === HINT_DISABLED_REASONS.PLAYER_TAKING_HINT_STEALABLE) {
      return appContent.inGame.hints.hintButtonTextWhileDisabledWhilePlayerInHintFlowStealable(playerNameTakingHint);
    } else if (disabledReason === HINT_DISABLED_REASONS.NO_MORE_HINTS) {
      return appContent.inGame.hints.hintButtonTextAfterTakingAllHints({liveHintsEnabled: liveHintsEnabled});
    } else if ([
      HINT_DISABLED_REASONS.HINT_LOCK_STARTING,
      HINT_DISABLED_REASONS.HINT_LOCK_TOOK_HINT,
      HINT_DISABLED_REASONS.HINT_LOCK_TOOK_LIVE_HINT,
    ].includes(disabledReason)) {
      return appContent.inGame.hints.hintButtonTextDisabledFromStartingHintLock({
        lockTime: realtimeHintLockedTime,
        isLiveHint: isLiveHintButton,
        liveHintsEnabled: liveHintsEnabled,
      });
    } else if (disabledReason === HINT_DISABLED_REASONS.LIVE_HINTS_NO_URL_FOR_TEAM) {
      return appContent.inGame.hints.liveHintsEnabledButNotForTeam;
    }
    // Hint Button is enabled
    else if (game.hintLocking.enabled) {
      return appContent.inGame.hints.hintButtonTextWithHintLocking({isLiveHintButton, liveHintsEnabled});
    } else {
      return appContent.inGame.hints.hintButtonText({penaltyTime: onlyWalkthroughLeft ? realtimeWalkthroughPenalty : realtimeHintPenalty, isLiveHintButton, liveHintsEnabled});
    }
  };

  useInterval(() => {
    setRealtimeHintPenalty(splitMinutes(getHintPenalty()));
    setRealtimeWalkthroughPenalty(splitMinutes(getWalkthroughPenalty()));
    setRealtimeHintLockedTime(splitMinutes(getHintWillUnlockTimeInMin()));
  }, 1000);

  const popupContent = {
    title: currentHint && (
      appContent.inGame.hints.hintQuestionsModalTitle
    ),
    content: currentHint && (
      currentHint.isWalkthrough ? appContent.inGame.hints.walkthroughPopup.content({
        walkthroughPenalty: splitMinutes(getWalkthroughPenalty()),
        hintPenalty: splitMinutes(getHintPenalty()),
      }) : currentHint.question
    ),
  };

  return <>
    {hasHints &&
      <Button
        onClick={() => askTakeHint({isLiveHint: false})}
        block
        disabled={disableHintButton}
        variant="dark"
      >
        {getHintButtonText({isLiveHintButton: false, liveHintsEnabled: liveHintButton.featureEnabled})}
      </Button>
    }

    {liveHintButton.featureEnabled && (
      <Button
        onClick={() => askTakeHint({isLiveHint: true})}
        block
        disabled={liveHintButton.disabled}
        variant="dark"
      >
        {getHintButtonText({isLiveHintButton: true, liveHintsEnabled: liveHintButton.featureEnabled})}
      </Button>
    )}

    {/*Hint Questions*/}
    <HintQuestionModal
      show={showingHintQuestion && !showingConfirmationModal}
      onHide={() => {
      }} // can not be closed
      title={popupContent.title}
      subtitleSlot={<div className="hint-summary">
        {hintSummary?.map(({id, isAvailable, isWalkthrough, canNotGoBack}, i) => <HintSummarySegement
          key={id}
          isAvailable={isAvailable}
          isWalkthrough={isWalkthrough}
          canNotGoBack={canNotGoBack}
          index={i}
          viewingHint={currentHint?.id === id}
        />)}
      </div>}
      contentTopSlot={<>
        <div class="d-flex flex-nowrap mb-3" style={{gap: "5px"}}>
          <CountdownButton 
            className={hintNavigationButtonClass} 
            variant="info"
            throttleTime={500}
            block={true}
            disabled={!canGoBack}
            onClick={goToPreviousHint}
          >
            Previous
            {canGoBack && <div style={{fontSize: "10px"}}>1 use remaining</div>}
          </CountdownButton>
          <div></div>
          <CountdownButton
            className={hintNavigationButtonClass}
            variant="info"
            throttleTime={500}
            block={true}
            disabled={disableNoAnswer}
            onClick={() => {
              answerHint(false);
            }}
          >
            Next
          </CountdownButton>
        </div>
        <div className="text-no-wrap text-muted text-center mb-1" style={{fontSize: "12px"}}>{progressText}</div>
      </>}
      content={popupContent.content}
      hideCloseButton={true}
      buttons={[
        {
          id: "take-hint",
          className: hintNavigationButtonClass,
          text: currentHint?.isWalkthrough ?
            appContent.inGame.hints.hintQuestionsModalButtons.yesForWalkthrough({penalty: realtimeWalkthroughPenalty, ignorePenalty: currentHint?.ignorePenalty ?? false}) :
            appContent.inGame.hints.hintQuestionsModalButtons.yes({penalty: realtimeHintPenalty, ignorePenalty: currentHint?.ignorePenalty ?? false}),
          onClick: () => confirmTakeHint(() => {answerHint(true)}),
          disabled: disableYesAnswer,
          variant: "outline-info",
          throttleTime: 500,
        },
      ]}
      additionalFooter={(
        <div className="d-flex">
          <div className="text-muted text-normal-line-height">
            <InfoSquare className="mr-2 mt-1"/>
          </div>
          <div>
          <p className="text-muted info-p">
            {appContent.inGame.hints.hintHelpText}
          </p>
          <p className="text-muted info-p">
            {appContent.inGame.hints.hintHelpTextWalkthrough}
          </p>
          </div>
        </div>
      )}
    />
  </>;
};

HintButton.propTypes = {};

export default HintButton;
